'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { serviceType } from '@/lib/service'
import { useState } from 'react'

type SearchProps = {
  service: serviceType
  style?: string
}

export const Search: React.FC<SearchProps> = ({ style }) => {
  const [word, setWord] = useState<string>('')
  return (
    <div className={`${style} flex justify-center p-4 search-box md:hidden`}>
      <Input
        placeholder="全てのアイテムから探す"
        style={{ height: '47px' }}
        value={word}
        onChange={(e) => setWord(e.target.value)}
      />
      <Button
        onClick={() => {
          if (word === '') return
          window.location.href = `/itemlist?keyword=${word}`
        }}
        className="search-button button-color main-color text-white h-[47px]"
      >
        検索
      </Button>
    </div>
  )
}
